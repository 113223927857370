<template>
  <router-view />
</template>

<style lang="scss">
$btnBg: #fac03d;

.btns {
  width: 120px;
  height: 35px;
  line-height: 35px;
  border: 0;
  border-radius: 5px;
  background-color: $btnBg;
}

.midVerti {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0 !important;
  // background: #161f3d;

  #app {
    width: 100%;
    height: 100%;
    font-family: "PingFang";
  }
}

.toolBox {
  width: 97%;
  height: 60px;
  margin: 0 auto;
  // border-bottom: 1px solid #eee;
  display: flex;
  position: relative;

  button {
    background-color: $btnBg !important;
    color: #333 !important;
  }

  .notice {
    height: 60px;
    line-height: 60px;
  }
}

.confirm {
  background-color: $btnBg !important;
}



.pagesBtns {
  width: 190px;
  height: 70px;
  float: right;

  button {
    margin-right: 20px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

}

.btn {
  width: 100%;
  height: 70px;

  button {
    width: 120px;
    margin-right: 20px;
  }
}

.rowClass {
  height: 100px;
}


.thinClass {
  height: 50px;
}


.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 11111;

  .popup {
    width: 1202px;
    height: 650px;
    background: url("@/assets/bigScreen/bg_cxtan@2x.png");
    background-size: 100% 100%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    .title {
      width: 100%;
      height: 25px;
      line-height: 25px;
      text-align: center;
      color: #253563;
      position: relative;

      .close {
        width: 15px;
        height: 15px;
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;
      }
    }

    .content {
      width: calc(100% - 40px);
      height: 580px;
      padding: 20px;
    }

    .sales {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 50%;
        height: 290px;
        display: flex;

        .info {
          width: 200px;
          height: 100%;

          .head {
            width: 115px;
            height: 24px;
            background: url("@/assets/bigScreen/bgimg_titlecxtan@2x.png");
            background-size: 100% 100%;
            line-height: 24px;
            color: #ffffff;
            text-indent: 10px;
            font-size: 15px;
          }

          .other {
            width: calc(100% - 40px);
            height: calc(100% - 30px);
            margin: 0 auto;
            display: flex;
            flex-direction: column;

            .otherItem {
              width: 100%;
              flex: 1;

              &:nth-of-type(1) {
                color: #fac03d;

                .spot {
                  background: #F9BF3D;
                }
              }

              &:nth-of-type(2) {
                color: #FF6F6F;

                .spot {
                  background: #FF6F6F
                }
              }

              &:nth-of-type(3) {
                color: #67AFFF;

                .spot {
                  background: #67AFFF
                }
              }

              .name {
                font-size: 14px;
                line-height: 20px;
                display: flex;
                height: 20px;

                .spot {
                  width: 6px;
                  height: 12px;
                  border-radius: 3px 3px 3px 3px;
                  vertical-align: middle;
                  margin-right: 3px;
                }

              }

              .value {
                font-size: 26px;
                font-weight: bold;

                .unit {
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 500;
                }
              }
            }
          }

        }

        .charts {
          width: calc(100% - 125px);
          height: 100%;
        }
      }
    }
  }
}
</style>
