<template>
    <a-layout-header class="navbar">
        <div class="logo">
            <img src="@/assets/logo@2x.png" alt="">
        </div>
        <!-- <div class="left-side">
            智慧养蜂·自然好蜜
        </div> -->
        <div class="right-side">
            <a-dropdown trigger="click">
                <div class="info">
                    <div class="imgs"> <img src="@/assets/icon_mag_uesr.png" /></div>
                    <span class="username">{{ userInfo.nickname }}</span>
                </div>

                <template #content>
                    <a-doption>
                        <a-space @click="changePsw">
                            <span>
                                修改密码
                            </span>
                        </a-space>
                    </a-doption>
                    <a-doption>
                        <a-space @click="changeName">
                            <span>
                                修改昵称
                            </span>
                        </a-space>
                    </a-doption>
                </template>
            </a-dropdown>

            <div class="logout">
                <icon-poweroff @click="handleLogout" />
            </div>
        </div>
        <a-modal v-model:visible="pswShow" okText="确认" @ok="updatePswFuc" :onBeforeOk="validatPsw" @cancel="canPsw"
            title-align="start">
            <template #title>
                <div class="modal-title">
                    <div class="icon midVerti"></div>
                    <div>修改密码</div>
                </div>
            </template>
            <a-form :model="pswInfo">
                <a-form-item label="输入新密码">
                    <a-input-password v-model="pswInfo.psw" placeholder="输入新密码" />
                </a-form-item>
                <a-form-item label="重复新密码">
                    <a-input-password v-model="pswInfo.repeatPsw" placeholder="再次输入新密码" />
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal v-model:visible="nameShow" okText="确认" @ok="updateNicknameFuc" :onBeforeOk="validatName" @cancel="canName"
            title-align="start">
            <template #title>
                <div class="modal-title">
                    <div class="icon midVerti"></div>
                    <div> 修改昵称</div>
                </div>
            </template>
            <a-input v-model="nickname" :maxLength="10" placeholder="输入新的昵称" />
        </a-modal>
    </a-layout-header>
</template>
<script>
import { ref, reactive, onMounted, toRefs } from "vue"
import { useRouter } from "vue-router"
import { updatePsw, updateNickname } from "@/api/index.js"
import { Message } from "@arco-design/web-vue"
import { Encrypt } from "@/utils/utils"
export default {
    setup() {
        const avatar = ref("https://img0.baidu.com/it/u=852235822,776474464&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500")

        const router = useRouter();

        const state = reactive({
            userInfo: {},
            pswShow: false,
            pswInfo: {
                psw: "",
                repeatPsw: ""
            },
            nickname: "",
            nameShow: false
        })

        onMounted(() => {
            let userInfo = localStorage.getItem("userInfo");
            state.userInfo = JSON.parse(userInfo)
        })

        const changePsw = () => {
            console.log("修改密码")
            state.pswShow = true;
        }

        const changeName = () => {
            state.nameShow = true;
        }

        const validatPsw = () => {
            if (state.pswInfo.psw === "" || state.pswInfo.repeatPsw === "") {
                Message.error({
                    content: "密码不能为空"
                })
            } else if (state.pswInfo.psw.length < 6) {
                Message.error({
                    content: "不能小于6位数"
                })
                return false
            } else if (state.pswInfo.psw !== state.pswInfo.repeatPsw) {
                Message.error({
                    content: "两次密码不一致"
                })
                return false;
            } else {
                return true
            }


        }

        const updatePswFuc = () => {
            let data = {
                password: Encrypt(state.pswInfo.psw),
                confirmPassword: Encrypt(state.pswInfo.repeatPsw)
            }
            updatePsw(data).then(res => {
                if (res) {
                    Message.success({
                        content: "修改成功！",
                        onClose: () => {
                            localStorage.clear();
                            router.push({
                                path: "/login"
                            })
                        }
                    })
                }
            })
        }

        // 退出登录  
        const handleLogout = () => {
            localStorage.clear();
            router.push({
                path: "/login"
            })
        }

        const updateNicknameFuc = () => {
            // 修改昵称
            let data = {
                nickname: state.nickname.trim()
            }
            updateNickname(data).then(res => {
                console.log(res, "修改成功")
                Message.success({
                    content: "修改成功！",
                })
                state.userInfo.nickname = state.nickname.trim()
                localStorage.setItem("userInfo", state.userInfo)
            })
        }

        const validatName = () => {
            if (state.nickname.trim() === "") {
                Message.error({
                    content: "请输入昵称",
                })
                return false
            } else {
                return true
            }
        }

        const canPsw = () => {
            state.pswInfo = {
                psw: "",
                repeatPsw: ""
            }
        }

        const canName = () => {
            state.nickname = ""
        }

        return {
            ...toRefs(state),
            changePsw,
            handleLogout,
            updatePswFuc,
            validatPsw,
            changeName,
            updateNicknameFuc,
            validatName,
            canPsw,
            canName
        }


    }
}



</script>
<style lang="scss" scoped>
.navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.logo {
    width: 280px;
    height: 100%;
    text-align: center;

    img {
        width: 187px;
        height: 61px;
        vertical-align: middle;
    }
}


.left-side {
    width: 280px;
    align-items: center;
    padding-left: 20px;
    color: #787878;
    font-size: 18px;
    text-align-last: justify;
}

.center-side {
    flex: 1;
}

.right-side {
    width: 200px;
    display: flex;
    padding-right: 20px;
    list-style: none;
    :deep(.locale-select) {
        border-radius: 20px;
    }

    .info {
        width: 150px;
        height: 100%;
        display: flex;

    }

    .imgs {
        width: 40px;
        height: 100%;
        text-align: center;
        position: relative;

        img {
            width: 20px;
            height: 20px;
            position: absolute;
            top: 25px;
        }
    }


    .username {
        width: 100px;
        text-align: left;
        height: 100px;
        display: inline-block;
        line-height: 100px;
        cursor: pointer;
    }


    .logout {
        font-size: 20px;
        font-weight: bold;
        margin-left: 20px;
        cursor: pointer;
    }

}
</style>