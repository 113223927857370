<template>
    <a-layout>
        <HEADER />
        <a-layout>
            <NAVBAR />
            <a-layout-content>
                <router-view class="main" v-slot="{ Component }">
                    <component :is="Component" />
                </router-view>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>
<script>
import { defineComponent, ref, watch } from 'vue';
import { Message } from '@arco-design/web-vue';
import { useRouter, useRoute } from 'vue-router';
import {
    IconHome,
    IconCalendar,
} from '@arco-design/web-vue/es/icon';
import NAVBAR from "@/layouts/components/navbar/navbar.vue"
import FOOTER from "@/components/footer/index.vue"
import HEADER from "@/layouts/components/header/index.vue"
export default defineComponent({
    components: {
        IconHome,
        IconCalendar,
        FOOTER,
        NAVBAR,
        HEADER
    },
    setup() {

        const router = useRouter();
        const route = useRoute();

        let title = ref("基础数据")
        return {
            title
        };
    }
});
</script>
<style scoped>
.arco-layout {
    width: 100%;
    height: 100%;
    /* background: linear-gradient(90deg, #F3F6FF, #FCFDFF); */
    background: #F3F6FF;
    overflow-y: hidden;
}


:deep(.arco-layout-sider-light) .logo {
    background: var(--color-fill-2);
}

:deep(.arco-layout-header) {
    height: 101px;
    line-height: 101px;
    /* background: var(--color-bg-3); */
}

:deep(.arco-layout-footer) {
    height: 48px;
    color: var(--color-text-2);
    font-weight: 400;
    font-size: 14px;
    line-height: 48px;
    text-align: center;
}

.main {
    width: calc(100% - 60px);
    height: calc(100% - 30px);
    background: #FFFFFF;
    box-shadow: 3px 7px 10px 0px rgba(210, 220, 248, 0.58);
    border-radius: 24px;
    margin: 0 auto;
    /* position: relative;
    top:50%;
    transform: translateY(-50%); */

}
</style>