import { createRouter, createWebHistory } from 'vue-router'
import layouts from "@/layouts/index.vue"

const routes = [
  // {
  //   path: '/',
  //   redirect: '/dashboard' // 重定向到默认页面
  // },
  {
    path: "/exhibition",
    name: "exhibition",
    meta: {
      title: "智慧蜂车",
      hide: true,
    },
    component: () => import('../views/exhibition/index.vue')
  },
  {
    path: "/userMonitor",
    name: "userMonitor",
    meta: {
      title: "摄像头",
      hide: true,
    },
    component: () => import('../views/dashboard/adoptSys/third/monitor')

  },
  {
    path: "/trace",
    name: "traceIndex",
    meta: {
      title: "云蜂场蜂蜜品质溯源"
    },
    component: () => import("../views/third/trace/index.vue"),
    children: [
      {
        path: "",
        name: "trace",
        meta: {
          title: "云蜂场蜂蜜品质溯源"
        },
        component: () => import("../views/third/trace/trace.vue"),
      },
      {
        path: "nectar",
        name: "nectar_trace",
        meta: {
          title: "蜜源介绍"
        },
        component: () => import("../views/third/trace/nectar.vue"),
      },
      {
        path: "farm",
        name: "farm_trace",
        meta: {
          title: "蜂场介绍"
        },
        component: () => import("../views/third/trace/farm.vue"),
      }
    ]
  },
  {
    path: "/digitalScreen",
    name: "digitalScreen",
    meta: {
      title: "智慧可视化大屏",
      hide: true,
    },
    component: () => import("../views/digitalScreen/index"),
    children: [
      {
        path: "home",
        name: "digital",
        component: () => import("../views/digitalScreen/home/index")
      },
      {
        path: "detail",
        name: "screenDetail",
        component: () => import("../views/digitalScreen/detail/index")
      },
      {
        path: "honey",
        name: "honeydistribution",
        component: () => import("../views/digitalScreen/honey/index")
      },
      {
        path: "supplyChain",
        name: "supplyChain",
        component: () => import("../views/digitalScreen/supplyChain/index")
      },
      {
        path: "expert",
        name: "expert",
        component: () => import("../views/digitalScreen/expert/index")
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: "用户登录",
      hide: true,

    },
    component: () => import('../views/login/index.vue')
  },
  {
    path: "/select",
    name: "select",
    meta: {
      title: "入口选择",
      hide: true,
    },
    component: () => import("../views/login/select.vue")
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: layouts,
    meta: {
      title: "控制中心"
    },
    children: [
      {
        path: "",
        name: "basic",
        meta: {
          title: "基础管理",
          icon: require("../assets/icon_mag_jichu.png")
        },
        children: [
          {
            path: "",
            name: "basicSetting",
            meta: {
              title: "接口设置",
            },
            component: () => import("@/views/dashboard/basic/index.vue")
          },

          {
            path: "farmSetting",
            name: "settings",
            meta: {
              title: "蜂场管理",
            },
            component: () => import("@/views/dashboard/adoptSys/farmManage/index.vue"),
            children: [
              {
                path: "",
                name: "farmSetting",
                meta: {
                  title: "蜂场设置",
                },
                component: () => import("@/views/dashboard/adoptSys/farmManage/farmSetting.vue"),
              },
              {
                path: "hiveSetting",
                meta: {
                  title: "蜂箱设置",
                },
                component: () => import("@/views/dashboard/adoptSys/farmManage/hiveBox.vue")
              },
              {
                path: "editFarm",
                meta: {
                  title: "新建/编辑蜂场",
                },
                component: () => import("@/views/dashboard/adoptSys/farmManage/editFarm.vue")
              },
              {
                path: "monitors",
                name: "monitors",
                meta: {
                  title: "监控管理",
                },
                component: () => import("@/views/dashboard/adoptSys/farmManage/monitor.vue")
              },
              {
                path: "weather",
                name: "weather",
                meta: {
                  title: "气象仪管理"
                },
                component: () => import("@/views/dashboard/adoptSys/farmManage/weather.vue")
              },
              {
                path: "productList",
                name: "productList",
                meta: {
                  title: "生产记录管理",
                },
                component: () => import("@/views/dashboard/adoptSys/farmManage/prodList.vue")
              },

              {
                path: "productRecords",
                name: "productRecords",
                meta: {
                  title: "生产记录管理",

                },
                component: () => import("@/views/dashboard/adoptSys/farmManage/prodRecords.vue")
              }
            ]
          },
          {
            path: "carSetting",
            name: "carSetting",
            meta: {
              title: "蜂车管理",
            },
            component: () => import("@/views/dashboard/basic/car/index.vue")
          },
          {
            path: "nectar",
            name: "nacIn",
            meta: {
              title: "蜜源管理",
            },
            component: () => import("@/views/dashboard/adoptSys/nectar/index.vue"),
            children: [
              {
                path: "",
                name: "nectar",
                meta: {
                  title: "蜜源介绍",
                },
                component: () => import("@/views/dashboard/adoptSys/nectar/nectar.vue"),
              },
              {
                path: "editNectar",
                name: "editNectar",
                meta: {
                  title: "添加/编辑蜜源",
                },
                component: () => import("@/views/dashboard/adoptSys/nectar/editNectar.vue"),
              },
            ]
          },
          {
            path: "resourcePool",
            name: "resoucePool",
            meta: {
              title: "资源库"
            },
            component: () => import("@/views/dashboard/basic/resourcePool.vue")
          }
        ]
      },
      /** 
       {
         path: "adoptSys",
         name: "adoptSystem",
         meta: {
           title: "认养系统",
           icon: require("../assets/icon_mag_rengyang.png")
         },
 
         children: [
           {
             path: "adoptManage",
             name: "adoptManage",
             meta: {
               title: "认养设置",
             },
             component: () => import("@/views/dashboard/adoptSys/adoptManage/index.vue"),
             children: [
               {
                 path: "",
                 name: "adoptSetting",
                 meta: {
                   title: "认养设置",
 
                 },
                 component: () => import("@/views/dashboard/adoptSys/adoptManage/setting.vue"),
               },
               {
                 path: "priceSetting",
                 meta: {
                   title: "认养价格设置"
                 },
                 component: () => import("@/views/dashboard/adoptSys/adoptManage/adoptPrice.vue")
               },
               {
                 path: "boxSetting",
                 meta: {
                   title: "蜂场蜂箱管理"
                 },
                 component: () => import("@/views/dashboard/adoptSys/adoptManage/hiveBox.vue")
               },
             ]
           },
 
           {
             path: "orderCenter",
             name: "orderManage",
             meta: {
               title: "订单管理",
             },
             component: () => import("@/views/dashboard/adoptSys/order/index.vue"),
             children: [
               {
                 path: "",
                 name: "orderList",
                 meta: {
                   title: "订单列表",
                 },
                 component: () => import("@/views/dashboard/adoptSys/order/orderManagement.vue")
               },
               {
                 path: "deliverCenter",
                 name: "deliverManage",
                 meta: {
                   title: "发货管理",
                 },
                 component: () => import("@/views/dashboard/adoptSys/order/deliveryManagement.vue")
               },
             ]
           },
           {
             path: "payInfos",
             name: "payInfos",
             meta: {
               title: "支付明细",
             },
             component: () => import("@/views/dashboard/adoptSys/order/payInfo.vue")
           },
           {
             path: "dynamics",
             name: "dynamics",
             meta: {
               title: "蜂场动态",
             },
             component: () => import("@/views/dashboard/adoptSys/farmManage/dynamics/index.vue"),
             children: [
               {
                 path: "",
                 name: "dynamicLists",
                 meta: {
                   title: "动态列表"
                 },
                 component: () => import("@/views/dashboard/adoptSys/farmManage/dynamics/farmDynamics.vue"),
               },
               {
                 path: "create",
                 name: "editDynamic",
                 meta: {
                   title: "新增/编辑动态",
                 },
                 component: () => import("@/views/dashboard/adoptSys/farmManage/dynamics/createDynamic.vue")
               },
             ]
           },
           {
             path: "activities",
             name: "activities",
             meta: {
               title: "蜂场活动",
             },
             component: () => import("@/views/dashboard/adoptSys/farmManage/farmActivity.vue")
           },
           {
             path: "member",
             name: "members",
             meta: {
               title: "用户管理",
             },
             component: () => import("@/views/dashboard/adoptSys/user/index.vue")
           }
         ]
       },
       {
         path: "traceability",
         name: "traceability",
         meta: {
           title: "溯源系统",
           icon: require("../assets/icon_mag_suyuan.png")
         },
         children: [
           {
             path: "analyse",
             name: "analyse",
             meta: {
               title: "分析统计"
             },
             component: () => import("@/views/dashboard/traceabilitySys/analyse/index.vue")
           },
           {
             path: "honey",
             name: "honey",
             meta: {
               title: "源蜜管理"
             },
             component: () => import("@/views/dashboard/traceabilitySys/sourceHoney/index.vue"),
             children: [
               {
                 path: "",
                 name: "honeyIndex",
                 component: () => import("../views/dashboard/traceabilitySys/sourceHoney/source.vue")
               },
               {
                 path: "editSource",
                 name: "editSource",
                 meta: {
                   title: "新增/编辑源蜜"
                 },
                 component: () => import("../views/dashboard/traceabilitySys/sourceHoney/editSource.vue")
               }
 
             ]
           },
           {
             path: "factory",
             name: "factory",
             meta: {
               title: "工厂管理"
             },
             component: () => import("@/views/dashboard/traceabilitySys/factory/index.vue"),
             children: [
               {
                 path: "",
                 name: "factoryIndex",
                 meta: {
                   title: "工厂列表"
                 },
                 component: () => import("../views/dashboard/traceabilitySys/factory/factory.vue")
               },
               {
                 path: "editFactory",
                 name: "editFactory",
                 meta: {
                   title: "新增/编辑工厂"
                 },
                 component: () => import("../views/dashboard/traceabilitySys/factory/editFactory.vue")
               }
 
             ]
           },
           {
             path: "process",
             name: "process",
             meta: {
               title: "加工管理"
             },
             component: () => import("../views/dashboard/traceabilitySys/process/index.vue"),
             children: [
               {
                 path: "",
                 name: "processIndex",
                 meta: {
                   title: "加工列表"
                 },
                 component: () => import("../views/dashboard/traceabilitySys/process/process.vue"),
               },
               {
                 path: "editProcess",
                 name: "editProcess",
                 meta: {
                   title: "新建/编辑加工"
                 },
                 component: () => import("../views/dashboard/traceabilitySys/process/editProcess.vue"),
               }
             ]
           },
           {
             path: "goods",
             name: "goods",
             meta: {
               title: "商品管理"
             },
             component: () => import("../views/dashboard/traceabilitySys/goods/index.vue"),
             children: [
               {
                 path: "",
                 name: "goodsIndex",
                 meta: {
                   title: "商品列表"
                 },
                 component: () => import("../views/dashboard/traceabilitySys/goods/goods.vue"),
               },
               {
                 path: "editGoods",
                 name: "editGoods",
                 meta: {
                   title: "新建/编辑商品"
                 },
                 component: () => import("../views/dashboard/traceabilitySys/goods/createGoods.vue"),
               }
             ]
           },
           {
             path: "q2code",
             name: "q2code",
             meta: {
               title: "溯源码"
             },
             component: () => import("../views/dashboard/traceabilitySys/q2code/index.vue"),
             children: [
               {
                 path: "",
                 name: "q2codeIndex",
                 meta: {
                   title: "溯源码列表"
                 },
                 component: () => import("../views/dashboard/traceabilitySys/q2code/q2code.vue"),
               },
               {
                 path: "editq2code",
                 name: "editq2code",
                 meta: {
                   title: "新建/编辑溯源码"
                 },
                 component: () => import("../views/dashboard/traceabilitySys/q2code/editQ2code.vue"),
               }
             ]
           }
         ]
       }, 
  */
      {
        path: "screenSys",
        name: "screenSys",
        meta: {
          title: "大屏系统",
          icon: require("../assets/icon_dpdata.png")
        },
        children: [
          {
            path: "setting",
            name: "screenSetting",
            meta: {
              title: "大屏设置"
            },
            component: () => import("@/views/dashboard/bigScreen/setting/index.vue")
          },
          {
            path: "honey",
            name: "screenHoney",
            meta: {
              title: "蜜源分布",
            },
            component: () => import("@/views/dashboard/bigScreen/honey/index.vue")
          },
          {
            path: "estate",
            name: "estate",
            meta: {
              title: "蜂产业统计",
            },
            component: () => import("@/views/dashboard/bigScreen/estate/index.vue"),
            children: [
              {
                path: "",
                name: "estateIndex",
                meta: {
                  title: "蜂产业统计",
                },
                component: () => import("@/views/dashboard/bigScreen/estate/estate.vue"),
              },
              {
                path: "edit",
                name: "estateEdit",
                meta: {
                  title: "添加/编辑合作蜂企",
                },
                component: () => import("@/views/dashboard/bigScreen/estate/edit.vue"),
              }
            ]
          },

          {
            path: "farmInfo",
            name: "screenFarmList",
            meta: {
              title: "蜂场数据"
            },
            component: () => import("@/views/dashboard/bigScreen/farmInfo/index.vue"),
            children: [
              {
                path: "",
                name: "screenFarmInfo",
                meta: {
                  title: "蜂场数据"
                },
                component: () => import("@/views/dashboard/bigScreen/farmInfo/setting.vue"),
              },
              {
                path: "tracks",
                name: "farmTracks",
                meta: {
                  title: "轨迹管理"
                },
                component: () => import("@/views/dashboard/bigScreen/farmInfo/track.vue"),
              },
              {
                path: "warning",
                name: "farmWarning",
                meta: {
                  title: "蜂场预警"
                },
                component: () => import("@/views/dashboard/bigScreen/farmInfo/warning.vue"),
              },
              {
                path: "logs",
                name: "farmLogs",
                meta: {
                  title: "蜂场日志"
                },
                component: () => import("@/views/dashboard/bigScreen/farmInfo/logs.vue"),
              },
              {
                path: "producesales",
                name: "producesales",
                meta: {
                  title: "产销数据统计"
                },
                component: () => import("@/views/dashboard/bigScreen/farmInfo/salesIncome.vue"),
              }
            ]
          },
          {
            path: "keeper",
            name: "keeper",
            meta: {
              title: "养蜂户管理"
            },
            component: () => import("@/views/dashboard/bigScreen/keeper/index.vue")
          },
          {
            path: "supplyChain",
            name: "supplyChainBE",
            meta: {
              title: "产销统计"
            },
            component: () => import("@/views/dashboard/bigScreen/supplyChain/index.vue")
          },
          {
            path: "expert",
            name: "expertBE",
            meta: {
              title: "技术指导",
            },
            component: () => import("@/views/dashboard/bigScreen/expert/index.vue"),
            children: [
              {
                path: "",
                name: "expertSetting",
                meta: {
                  title: "技术指导",
                },
                component: () => import("@/views/dashboard/bigScreen/expert/setting.vue"),
              },
              {
                path: "edit",
                name: "expertEdit",
                meta: {
                  title: "新增/编辑技术指导",
                },
                component: () => import("@/views/dashboard/bigScreen/expert/edit.vue"),
              },
              {
                path: "editPolicy",
                name: "policyEdit",
                meta: {
                  title: "新增/编辑政策动态",
                },
                component: () => import("@/views/dashboard/bigScreen/expert/editPolicy.vue"),
              }
            ]
          }
        ]
      }

    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



export default router



router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  let token = localStorage.getItem("token");
  if (!token) {
    if (to.path !== "/login") {
      if (to.path === "/userMonitor" || to.path === "/trace" || to.path === "/trace/nectar" || to.path === "/trace/farm" || to.path === "/exhibition") {
        next()
      } else {
        next("/login")
      }

    } else {
      next()
    }
  } else {
    if (token && to.path === '/') {
      next('/dashboard'); // 重定向到 /dashboard 页面
    } else {
      next(); // 继续正常导航
    }
  }

});

