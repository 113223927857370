import { createStore } from 'vuex'

export default createStore({
  state: {
    screenLogo:"",
    screenTitle:"",
    screenArea:"",
    screenNum:1,
    screenUnit:{
      area:"亩",
      weight:"千克",
      money:"万元"
    }

  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
