import axios from "axios";
import store from "@/store";
import router from "@/router";
import { getToken } from "@/utils/auth";
import { randomNumber } from "@/utils/utils.js";
import sha1 from "crypto-js/sha1";
import dayjs from "dayjs"
import { Message, MessageBox } from "@arco-design/web-vue";



const service = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 15000, // request timeout
});

// request interceptor
service.interceptors.request.use(
    (config) => {
        let timestamp = dayjs().unix();
        let nonce = randomNumber(6); //6位随机数
        let appid = randomNumber(6);
        let noSha1Data = appid + "" + timestamp + nonce + JSON.stringify(config.data) + "" + process.env.VUE_APP_SERECT;



        config.headers["authority-token"] =
            localStorage.getItem("token") || store.getters.getToken;
        config.headers["timestamp"] = timestamp;
        config.headers["nonce"] = nonce;
        config.headers["appid"] = appid;
        config.headers["sign"] = sha1(noSha1Data);

        // console.log(config,"confg")

        return config;
    },
    (error) => {
        // do something with request error
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    (response) => {
        const res = response.data;
        if (res.code === 200) {
            return res.data;
        } else {
            if (res.code) {
                if (res.code === 1004) {
                    localStorage.clear();
                } else
                    if (res.code === 999) {
                        localStorage.clear();
                        router.push({
                            path: "/login"
                        })
                    } else if (res.code === 5001) {
                        Message.error({
                            content: res.msg
                        })
                    }
                    else {
                        Message.error({
                            content: res.msg
                        })
                    }

            } else {
                return res;
            }
        }
    },
    (error) => {
        console.log("err" + error); // for debug
        if (
            error.code === "ECONNABORTED" ||
            error.message === "Network Error" ||
            error.message.includes("timeout")
        ) {
            Message.error({
                content: "请求超时，请稍后重试"
            })

        } else {
            Message.error({
                content: error.message
            })
        }

        return Promise.reject(error);
    }
);

export default service;
