<template>
    <a-layout-sider breakpoint="lg" :width="280">
        <a-menu :default-open-keys="defaultOpenKeys" :default-selected-keys="defaultSelectedKeys" :accordion="true"
            class="el-menu-vertical-demo">
            <a-sub-menu :key="item.name" v-for="(item, index) in menubar.children">
                <template #icon v-if="item.meta.icon !== ''">
                    <img :src="item.meta.icon" style="width:20px;height:20px" />
                </template>
                <template #title>
                    <strong>{{ item.meta.title }}</strong>
                </template>
                <a-menu-item style="text-indent: 20px;" :key="v.name" v-for="(v, i) in item.children"
                    @click="goPath(item, v)">
                    {{ v.meta.title }}
                </a-menu-item>
            </a-sub-menu>
        </a-menu>

    </a-layout-sider>
</template>
  
<script>
import { defineComponent, ref, reactive, toRefs, watch } from 'vue';
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router';

export default defineComponent({
    setup() {
        const router = useRouter();
        const route = useRoute();
        const collapsed = ref(false);
        const state = reactive({
            defaultOpenKeys: [],
            defaultSelectedKeys: [],
            defaultActive: '',
            selectedMenu: null, // 用于存储选中的菜单项
        });

        let menubar = ref({});
        let routes = router.options.routes[6];
        menubar = routes;



        // 去往指定的路由
        const goPath = (item, v) => {
            state.defaultSelectedKeys = [v.name];
            state.defaultOpenKeys = [item.name]

            let path = '';
            if (item.path === '' && v.path === '') {
                path = '';
            } else {
                if (item.path === '') {
                    path = v.path;
                } else {
                    path = item.path + '/' + v.path;
                }
            }

            // console.log(path, "--")

            router.push({
                path: '/dashboard/' + path,
            });
        };

        // 根据地址栏高亮当前选择的菜单项
        const highlightCurrentMenu = () => {
            const path = route.path;
            const paths = path.split('/');
            // console.log(paths, "--")
            if (paths.length >= 3) {
                const parentPath = paths[2];
                const childPath = paths.slice(3).join('/');
                const parentMenu = menubar.children.find(item => item.path === parentPath);

                if (parentMenu) {
                    const childMenu = parentMenu.children.find(item => item.path === childPath);
                    if (childMenu) {
                        state.defaultSelectedKeys = [childMenu.name];
                        state.defaultOpenKeys = [parentMenu.name];
                    }
                }
            }
        };


        // 初始化时高亮当前菜单
        highlightCurrentMenu();


        // 监听路由变化，更新菜单高亮
        watch(route, () => {
            highlightCurrentMenu();
        });


        return {
            route,
            collapsed,
            menubar,
            goPath,
            ...toRefs(state),
        };
    },
});
</script>
<style lang="scss"></style>
<style>
.arco-layout-sider {
    background: transparent !important;
    box-shadow: 0 0 0 0 !important;
}

.arco-menu {
    width: 232px;
    background: transparent !important;
    margin: 0 auto;
}

.arco-menu .arco-menu-inline {
    min-height: 52px;
    margin: 22px 0;
    padding: 0 25px;
}

.arco-menu .arco-menu-inline .arco-menu-inline-header {
    background: transparent !important;
    height: 52px;
    border-radius: 12px;
    font-size: 16px;
}

.arco-menu .arco-menu-inline .arco-menu-selected {
    background: #FAC03D !important;
    color: #404447 !important;
}

.arco-menu .arco-menu-inline .arco-menu-selected .arco-menu-icon-suffix {
    background: transparent !important;
}

.arco-menu .arco-menu-inline .arco-menu-inline-content .arco-menu-item {
    background: transparent !important;
    color: #7C8287 !important;
    margin: 20px 0;
}


.arco-menu .arco-menu-inline .arco-menu-inline-content .arco-menu-selected {
    color: #FC8800 !important;
}
</style>
